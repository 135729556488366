





















import { Inject, Mixins } from 'vue-property-decorator';
import { BTable, BTooltip } from 'bootstrap-vue';
import DeviceMixin from '@/mixins/device.mixin';
import ChoicePopup from '@/shared/components/choice-popup.vue';
import { AnnexeStatutEnum } from '@/shared/enums/annexe-statut.enum';
import { BusinessEntryEnum } from '@/shared/enums/business-entry.enum';
import { mixins } from 'vue-class-component';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { PrestationExpertiseVersionHistoryDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-version-history.dto';
import RapportExpertiseEstimationDPE from '@/shared/components/rapport-expertise/rapport-expertise-estimation-dpe.vue';
import Radios from '../radios.vue';
import { EstimationDpeResponse } from '@/shared/dtos/estimation-dpe-response.dto';
import { DpeEnum } from '../constants/tableDPE.const';
import { Dpe } from '../models/dpe.model';

@Component({
  name: 'EtiquetteDPE',
  components: {},
})
export default class EtiquetteDpe extends mixins(DeviceMixin) {
  @Prop() public value: Dpe;
  @Prop() public showDetail: boolean;

  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED

  //#endregion

  //#region WATCH
  //#endregion
  //#region EVENTS

  public onSelectDpeClick() {
    this.$emit('dpeSelected', this.value);
  }

  //#endregion
  //#region FUNCTIONS

  //#endregion
}
