








































































import { Inject, Mixins } from 'vue-property-decorator';
import { BTable, BTooltip } from 'bootstrap-vue';
import DeviceMixin from '@/mixins/device.mixin';
import ChoicePopup from '@/shared/components/choice-popup.vue';
import { AnnexeStatutEnum } from '@/shared/enums/annexe-statut.enum';
import { BusinessEntryEnum } from '@/shared/enums/business-entry.enum';
import { mixins } from 'vue-class-component';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { PrestationExpertiseVersionHistoryDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-version-history.dto';
import RapportExpertiseEstimationDPE from '@/shared/components/rapport-expertise/rapport-expertise-estimation-dpe.vue';
import Radios from '../radios.vue';
import { EstimationDpeResponse } from '@/shared/dtos/estimation-dpe-response.dto';
import EtiquetteDpe from '../etiquette-dpe.vue';
import { Dpe } from '@/shared/models/dpe.model';
import { DpeEnum } from '@/shared/constants/tableDPE.const';

@Component({
  name: 'RapportExpertiseDpe',
  components: {
    Radios,
    'rapport-expertise-estimation-dpe': RapportExpertiseEstimationDPE,
    'etiquette-dpe': EtiquetteDpe,
  },
})
export default class RapportExpertiseDpe extends mixins(DeviceMixin) {
  @Prop() public value: number;
  @Prop() public rapportId: number;
  @Prop() public caracteristiquesDPEs: any;
  @Prop() public annexeEnvironnements: any;
  @Prop() public annexesStatuts: any;
  @Prop() public state: any;
  @Prop() public version: any;

  public annexeStatutId?: number = null;
  public refDpe: Dpe[] = Object.values(DpeEnum);
  public annexeStatutObject: { [key: string]: number } = Object.values(AnnexeStatutEnum).reduce((acc, key) => {
    acc[key] = AnnexeStatutEnum[key as keyof typeof AnnexeStatutEnum];
    return acc;
  }, {} as { [key: string]: number });

  //#region LIFE CYCLES

  public beforeMount(): void {
    this.initDPE();
  }

  //#endregion

  //#region COMPUTED

  get isObtenu() {
    return this.annexeStatutId == AnnexeStatutEnum.Obtenu;
  }

  get isNonObtenu() {
    return this.annexeStatutId == AnnexeStatutEnum.NonObtenu;
  }

  get caracteristiqueDpeSelected() {
    return this.caracteristiquesDPEs.find((x: any) => x.id === this.value);
  }

  //#endregion

  //#region WATCH
  @Watch('annexeStatutId')
  public annexeStatutIdChanged(annexeStatut: number) {
    this.$emit('AnnexeDpeEmit', annexeStatut);
    this.annexeStatutId = annexeStatut;
  }

  //#endregion
  //#region EVENTS

  public onSelectDpe(dpe: Dpe) {
    this.$emit('AnnexeDpeEmit', this.annexeStatutId);
    this.$emit('input', dpe.id);
  }

  public onReceiveEstimation(estimationDpe: Dpe) {
    this.value = estimationDpe.id;
    this.$emit('AnnexeDpeEmit', this.annexeStatutId);
    this.$emit('input', estimationDpe.id);
  }

  public onDeleteDpeData() {
    this.value = null;
    this.annexeStatutId = AnnexeStatutEnum.NonObtenu;
    this.$emit('AnnexeDpeEmit', AnnexeStatutEnum.NonObtenu);
    this.$emit('input', null);
  }

  //#endregion
  //#region FUNCTIONS

  private initDPE(): void {
    const annexeDPE = this.annexeEnvironnements.find((x: any) => x.businessEntrySelectedId === BusinessEntryEnum.DPE);
    if (annexeDPE) this.annexeStatutId = annexeDPE.businessEntryQualificationId;
  }

  public getDPE(id: number): any {
    return this.refDpe.find((x: any) => x.id == id);
  }

  public isRapportVersionGreaterOrEqualsTo(version: number): boolean {
    return this.version && version >= version;
  }

  //#endregion
}
