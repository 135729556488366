



























































































































































import { EstimationDpeInput } from '@/shared/dtos/estimation-dpe.dto';
import { PriceHubbleDto } from '@/shared/dtos/price-hubble.dto';
import { Component, Inject, Mixins, Prop, Vue } from 'vue-property-decorator';
import { DossierPriceHubble } from '@/shared/models/dossier/dossier-pricehubble.model';
import { EstimationDpeResponse } from '@/shared/dtos/estimation-dpe-response.dto';
import store from '@/shared/store';
import { Dpe } from '@/shared/models/dpe.model';
import { DpeEnum } from '@/shared/constants/tableDPE.const';

@Component({
  name: 'rapport-expertise-estimation-dpe',
  components: {},
})
export default class RapportExpertiseEstimationDPE extends Vue {
  @Prop() public rapportId: number;
  @Prop() public title?: string;
  dossier: DossierPriceHubble = new DossierPriceHubble();
  estimation: Dpe = null;

  airFlowTypesOptions: { [key: string]: string } = null;
  glazingTypesOptions: { [key: string]: string } = null;
  hotWaterTypesOptions: { [key: string]: string } = null;
  heatingTypesOptions: { [key: string]: string } = null;
  hotWaterGenerationTypesOptions: { [key: string]: string } = null;
  isolationRenovationPeriodOptions: { [key: string]: string } = null;

  //#region LIFE CYCLES

  public async mounted() {
    store.isLoading = true;
    await this.loadDossier();
    await this.loadReferentiel();
    store.isLoading = false;
  }

  //#endregion
  //#region COMPUTED

  //#endregion
  //#region WATCH
  //#endregion
  //#region EVENTS

  async onClickGetEstimation() {
    store.isLoading = true;
    await this.GetEstimation();
    this.$emit('estimationDPE', this.estimation);
    store.isLoading = false;
    this.$bvModal.hide('modal-estimation-dpe');
  }
  //#endregion

  //#region FUNCTIONS
  private async loadDossier() {
    await Vue.prototype.$donneesEstimationImmobiliereApiService
      .getDossierByRapportId(this.rapportId)
      .then(async (dossier: DossierPriceHubble) => {
        this.dossier = dossier;
      })
      .catch(async (error: any) => {
        Vue.prototype.$notificationService.error(`Une erreur est survenue lors de la récupération du dossier.`);
        await Vue.prototype.$logStoreService.error(`Dossier - ERROR`, error, 'Dossier');
      });
  }

  private async loadReferentiel() {
    await Vue.prototype.$donneesEstimationImmobiliereApiService
      .getReferentielDpe()
      .then(async (refs: Array<{ [key: string]: string }>) => {
        this.glazingTypesOptions = refs[0];
        this.hotWaterTypesOptions = refs[1];
        this.heatingTypesOptions = refs[2];
        this.hotWaterGenerationTypesOptions = refs[3];
        this.isolationRenovationPeriodOptions = refs[4];
        this.airFlowTypesOptions = refs[5];
      })
      .catch(async (error: any) => {
        Vue.prototype.$notificationService.error(`Une erreur est survenue lors de la récupération du dossier.`);
        await Vue.prototype.$logStoreService.error(`Estimation DPE - ERROR`, error, 'Referentiel');
      });
  }

  private async GetEstimation() {
    await Vue.prototype.$donneesEstimationImmobiliereApiService
      .getEstimation(this.dossier.id, this.dossier.property)
      .then(async (estimation: EstimationDpeResponse) => {
        this.estimation = Object.values(DpeEnum).find((f) => f.minusLabel == estimation.energyLabel.value);
      })
      .catch(async (error: any) => {
        Vue.prototype.$notificationService.error(
          `Une erreur est survenue lors de l'estimation du DPE. Assurez vous que l'adresse, le type du bien ainsi que le titre de l'annonce soit correctement complétés.`
        );
        await Vue.prototype.$logStoreService.error(`Estimation - ERROR`, error, 'Estimation');
      });
  }

  //#endregion
}
